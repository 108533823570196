
const sampleRestaurantData = [
    {
      restaurantId: "BROOK001",
      name: "Brooklyn Brewery",
      contact: {
        email: "info@brooklynbrewery.com",
        phone: "+17185551234",
        website: "https://www.brooklynbrewery.com",
      },
      cuisines: "American",
      description: "A hip, trendy brewery located in the heart of Brooklyn, featuring a rotating selection of craft beers brewed in-house. Known for its relaxed vibe, guided tours, and seasonal brews.",
      rating: 4.5,
      dietaryOptions: {
        glutenFree: true,
        halal: false,
        kosher: false,
        vegan: true,
        vegetarian: true,
      },
      features: {
        delivery: false,
        dineIn: true,
        outdoorSeating: true,
        parking: "true",
        takeout: false,
        wifi: true,
      },
      hours: {
        friday: { open: "17:00", close: "01:00" },
        monday: { open: "17:00", close: "23:00" },
        saturday: { open: "12:00", close: "01:00" },
        sunday: { open: "12:00", close: "20:00" },
        thursday: { open: "17:00", close: "23:00" },
        tuesday: { open: "17:00", close: "23:00" },
        wednesday: { open: "17:00", close: "23:00" },
      },
      images: [
        "https://brooklynbrewery.com/wp-content/uploads/2020/01/IQY_3198-scaled.jpg",
      ],
      location: {
        address: "79 N 11th St",
        city: "Brooklyn",
        state: "NY",
        country: "USA",
        postalCode: "11249",
        coordinates: "40.7217° N, 73.9577° W",
      },
      popularDishes: ["Brooklyn Lager", "Sorachi Ace", "Black Chocolate Stout"],
      priceRange: "$$",
      reservationLink: "https://tours.brooklynbrewery.com",
      specialties: ["Seasonal Brews", "Beer Flights"],
      tags: ["craft beer", "brewery tours", "hipster"],
    },
    {
      restaurantId: "PASTA001",
      name: "Pasta Paradise",
      contact: {
        email: "reservations@pastaparadise.com",
        phone: "+12125555678",
        website: "https://www.pastaparadise.com",
      },
      cuisines: "Italian",
      description: "A cozy Italian restaurant offering hand-made pastas and an extensive selection of regional wines. Perfect for a romantic evening or a family gathering in an authentic Italian setting.",
      rating: 4.2,
      dietaryOptions: {
        glutenFree: true,
        halal: false,
        kosher: false,
        vegan: false,
        vegetarian: true,
      },
      features: {
        delivery: false,
        dineIn: true,
        outdoorSeating: true,
        parking: "true",
        takeout: true,
        wifi: false,
      },
      hours: {
        friday: { open: "17:00", close: "00:00" },
        monday: { open: "17:00", close: "23:00" },
        saturday: { open: "17:00", close: "00:00" },
        sunday: { open: "17:00", close: "22:00" },
        thursday: { open: "17:00", close: "23:00" },
        tuesday: { open: "17:00", close: "23:00" },
        wednesday: { open: "17:00", close: "23:00" },
      },
      images: [
        "https://fnb.qdc.vn/pictures/catalog/pasta-paradise/thiet-ke-nha-hang-pasta-paradise-04.jpg",
      ],
      location: {
        address: "456 9th Ave",
        city: "New York",
        state: "NY",
        country: "USA",
        postalCode: "10018",
        coordinates: "40.7549° N, 73.9954° W",
      },
      popularDishes: ["Carbonara", "Osso Buco", "Tiramisu"],
      priceRange: "$$$",
      reservationLink: "https://bookings.pastaparadise.com",
      specialties: ["Hand-made Pasta", "Regional Italian Wines"],
      tags: ["romantic", "wine bar", "homemade pasta"],
    },
    {
      restaurantId: "PETIT001",
      name: "Le Petit Bistro",
      contact: {
        email: "bonjour@lepetitbistro.com",
        phone: "+12125557890",
        website: "https://www.lepetitbistronyc.com",
      },
      cuisines: "French",
      description: "Step into Paris at this intimate French bistro. From coq au vin to crème brûlée, experience classic French flavors in a warm, romantic atmosphere.",
      rating: 4.8,
      dietaryOptions: {
        glutenFree: true,
        halal: false,
        kosher: false,
        vegan: false,
        vegetarian: true,
      },
      features: {
        delivery: false,
        dineIn: true,
        outdoorSeating: true,
        parking: "true",
        takeout: false,
        wifi: false,
      },
      hours: {
        friday: { open: "17:30", close: "00:00" },
        monday: { open: "17:30", close: "23:00" },
        saturday: { open: "17:30", close: "00:00" },
        sunday: { open: "17:30", close: "22:00" },
        thursday: { open: "17:30", close: "23:00" },
        tuesday: { open: "17:30", close: "23:00" },
        wednesday: { open: "17:30", close: "23:00" },
      },
      images: [
        "https://images.squarespace-cdn.com/content/v1/5ae6429de17ba32c909cb961/1555790112559-M8IA7NN0718E0T1BK11A/IMG_4468+edited.jpg?format=1500w",
      ],
      location: {
        address: "234 W 56th St",
        city: "New York",
        state: "NY",
        country: "USA",
        postalCode: "10019",
        coordinates: "40.7654° N, 73.9833° W",
      },
      popularDishes: ["Coq au Vin", "Beef Bourguignon", "Crème Brûlée"],
      priceRange: "$$$",
      reservationLink: "https://bookings.lepetitbistronyc.com",
      specialties: ["French Wine Selection", "House-made Pâté"],
      tags: ["romantic", "wine bar", "authentic French"],
    },
    {
      restaurantId: "SPICE001",
      name: "Spice Route",
      contact: {
        email: "info@spiceroutenyc.com",
        phone: "+12125554321",
        website: "https://www.spiceroutenyc.com",
      },
      cuisines: "Indian",
      description: "A vibrant spot serving authentic Indian cuisine. Known for its flavorful dishes and a variety of vegetarian and vegan options.",
      rating: 4.3,
      dietaryOptions: {
        glutenFree: true,
        halal: true,
        kosher: false,
        vegan: true,
        vegetarian: true,
      },
      features: {
        delivery: true,
        dineIn: true,
        outdoorSeating: false,
        parking: "true",
        takeout: true,
        wifi: true,
      },
      hours: {
        friday: { open: "11:30", close: "23:30" },
        monday: { open: "11:30", close: "22:30" },
        saturday: { open: "11:30", close: "23:30" },
        sunday: { open: "11:30", close: "22:00" },
        thursday: { open: "11:30", close: "22:30" },
        tuesday: { open: "11:30", close: "22:30" },
        wednesday: { open: "11:30", close: "22:30" },
      },
      images: [
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/13/d0/62/eb/the-spice-route.jpg?w=1200&h=-1&s=1",
      ],
      location: {
        address: "567 10th Ave",
        city: "New York",
        state: "NY",
        country: "USA",
        postalCode: "10036",
        coordinates: "40.759° N, 73.9973° W",
      },
      popularDishes: ["Butter Chicken", "Palak Paneer", "Gulab Jamun"],
      priceRange: "$$",
      reservationLink: "https://reservations.spiceroutenyc.com",
      specialties: ["Tandoori Specialties", "House-made Chutneys"],
      tags: ["spicy", "vegetarian-friendly", "buffet"],
    },
    {
      restaurantId: "SUSHI001",
      name: "Sushi Sensation",
      contact: {
        email: "info@sushisensation.com",
        phone: "+12125559876",
        website: "https://www.sushisensation.com",
      },
      cuisines: "Japanese",
      description: "A fine dining restaurant for best quality sashimis and fresh salmon rolls!",
      rating: 4.9,
      dietaryOptions: {
        glutenFree: true,
        halal: false,
        kosher: false,
        vegan: false,
        vegetarian: false,
      },
      features: {
        delivery: false,
        dineIn: true,
        outdoorSeating: false,
        parking: "true",
        takeout: false,
        wifi: false,
      },
      hours: {
        friday: { open: "18:00", close: "00:00" },
        monday: { open: "18:00", close: "23:00" },
        saturday: { open: "18:00", close: "00:00" },
        sunday: { open: "18:00", close: "23:00" },
        thursday: { open: "18:00", close: "23:00" },
        tuesday: { open: "18:00", close: "23:00" },
        wednesday: { open: "18:00", close: "23:00" },
      },
      images: [
        "https://assets.bonappetit.com/photos/57e07ecb26bdf8de5bbcabeb/16:9/w_1280,c_limit/uchi-restaurant-auston-646.jpg",
      ],
      location: {
        address: "789 5th Ave",
        city: "New York",
        state: "NY",
        country: "USA",
        postalCode: "10065",
        coordinates: "40.7641° N, 73.973° W",
      },
      popularDishes: ["Fatty Tuna Nigiri", "Dragon Roll", "Uni Sashimi"],
      priceRange: "$$$$",
      reservationLink: "https://reservations.sushisensation.com",
      specialties: ["Chef's Omakase", "Rare Sake Collection"],
      tags: ["omakase", "sake bar", "michelin-starred"],
    },
    {
      restaurantId: "TACO001",
      name: "Taco Fiesta",
      contact: {
        email: "hola@tacofiesta.com",
        phone: "+12125553210",
        website: "https://www.tacofiestanyc.com",
      },
      cuisines: "Mexican",
      description: "A lively Mexican eatery known for its vibrant flavors and festive atmosphere. Enjoy authentic tacos, hand-crafted margaritas, and traditional dishes made with fresh, local ingredients",
      rating: 4.7,
      dietaryOptions: {
        glutenFree: true,
        halal: true,
        kosher: false,
        vegan: true,
        vegetarian: true,
      },
      features: {
        delivery: true,
        dineIn: true,
        outdoorSeating: true,
        parking: "true",
        takeout: true,
        wifi: true,
      },
      hours: {
        friday: { open: "11:30", close: "00:00" },
        monday: { open: "11:30", close: "22:00" },
        saturday: { open: "11:30", close: "00:00" },
        sunday: { open: "11:30", close: "22:00" },
        thursday: { open: "11:30", close: "23:00" },
        tuesday: { open: "11:30", close: "22:00" },
        wednesday: { open: "11:30", close: "22:00" },
      },
      images: [
        "https://www.insidehook.com/wp-content/uploads/2023/08/Pink-Taco-interior-2.jpg?fit=1200%2C800",
      ],
      location: {
        address: "321 E Houston St",
        city: "New York",
        state: "NY",
        country: "USA",
        postalCode: "10002",
        coordinates: "40.7217° N, 73.9856° W",
      },
      popularDishes: ["Al Pastor Tacos", "Queso Fundido", "Churros"],
      priceRange: "$$",
      reservationLink: "https://reservations.tacofiestanyc.com",
      specialties: ["Hand-pressed Tortillas", "Mezcal Cocktails"],
      tags: ["lively", "authentic", "margaritas"],
    },
  ];
  
  export default sampleRestaurantData;
  
